define("discourse/plugins/discourse-lexicon-plugin/discourse/routes/base-deeplink", ["exports", "@ember/object", "@ember/service", "discourse/lib/url", "discourse/routes/discourse"], function (_exports, _object, _service, _url, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DeeplinkRoute extends _discourse.default {
    is_pm = false;
    redirect_to_app = false;
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel(params) {
      this.is_pm = (params.to.queryParams.is_pm ?? "false") === "true";
      this.redirect_to_app = params.to.queryParams.is_pm === undefined;
    }
    model(_ref) {
      let {
        link
      } = _ref;
      const isAndroid = navigator.userAgent.match(/Android/i);

      // `iOS` allows us to programmatically open links without user interaction
      // Android sometimes causes issues. Only attempt to open it automatically
      // if we're not on Android.
      if (!isAndroid || !this.siteSettings.lexicon_app_scheme) {
        this.open(link);
      }
      return {
        link,
        is_pm: this.is_pm,
        redirect_to_app: this.redirect_to_app
      };
    }
    open(link) {
      const isMobile = navigator.userAgent.match(/(iPad|iPhone|iPod|Android)/g);
      if (this.siteSettings.lexicon_app_scheme && isMobile) {
        // Using `DiscourseURL.redirectTo` allows us to mock this in tests
        // Additionally, Discourse won't actually call it in tests since, according
        // to them, that kills the test runner.
        // Internally, this uses `window.location = url` rather than `window.location.replace`.

        const scene = this.is_pm ? "message-detail" : "post-detail";
        _url.default.redirectTo(`${this.siteSettings.lexicon_app_scheme}://${scene}/${link}`);
      }
      this.router.transitionTo(`/${link}`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "open", [_object.action]))();
  }
  _exports.default = DeeplinkRoute;
});